//changed

import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderUnderlined from "../components/HeaderUnderlined";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Gatsby = ({ data }) => {
  const { title, body, relationships, field_image } = data.allNodePage.nodes[0];
  const thumbnail = getImage(
    relationships.field_image.localFile.childImageSharp
  );
  return (
    <Layout>
      <Seo title={title} />
      <Container fluid="lg">
        <Row>
          <Col
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            style={{ backgroundColor: "#66329787" }}
          >
            <div className="shadow-sm bg-secondary">
              <GatsbyImage image={thumbnail} alt={field_image.alt} />
            </div>
          </Col>
          <Col className="p-5 bg-white">
            <HeaderUnderlined titlesrc={title} className="fadein" />
            <div
              dangerouslySetInnerHTML={{
                __html: body.processed,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  {
    allNodePage(filter: { path: { alias: { eq: "/gatsby" } } }) {
      nodes {
        id
        title
        body {
          processed
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 70
                  width: 500
                  height: 500
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        field_image {
          alt
        }
      }
    }
  }
`;

export default Gatsby;
